import {StyleSheet, Dimensions} from "react-native";


const windowHeight = Dimensions.get("window").height

const BaseStyle = StyleSheet.create({
    blackBackground: {
        backgroundColor: '#0000',
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 10,
        color: "#969696",
        marginBottom: 10, // Increased margin between boxes
        width: '100%', // Shortened width

    },
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 12,
        paddingHorizontal: 32,
        backgroundColor: '#eab923',
    },
    iconButton: {
        backgroundColor: "#eab923",
        marginBottom: 18,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "12px",
        padding: 10,
        marginRight: 10,
        flex:1,
    },
    icon: {
        fontSize: 50,
        lineHeight: 25,
        fontWeight: 'bold',
        letterSpacing: 0.25,
        color: "#969696",
    },
    buttonText: {
        fontSize: 16,
        lineHeight: 21,
        fontWeight: 'bold',
        letterSpacing: 0.25,
        color: 'white',
        textAlign: "center",
    },
    container: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "center",
        flexWrap: "wrap",
        marginLeft: "3%",
        marginRight: "3%",
        color: "white",
        flex: 1,
 },
    containerDesktop: {
        flexWrap: "wrap",
        marginLeft: "20%",
        marginRight: "25%",
        color: "white",
        marginBottom: 20,
  },

    listItem: {
        flexWrap: 'wrap',
        paddingRight: 25,
        paddingLeft: 25,
        paddingBottom: "5%",
        marginBottom: 35,
        paddingTop: 15,
        backgroundColor: "#181a1f",

        fontSize: 30,
        borderRadius: 3,
        color: "white",
        borderColor: "#555E6C",
        borderWidth: 1,
        minWidth: "80%",
    },

    headerbar: {
        backgroundColor: "#fff",
        elevation: 0,
        shadowOpacity: 0,
    },
    fileNameText: {
        margin: 10,
        fontSize: 15,
        fontWeight: "bold",
    },

    header: {
        color: "#222",
        fontSize: 28,
        fontWeight: "bold",
        paddingLeft: 20,
        paddingTop: 20
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },


    row: {
        flexDirection: "row",
    },

    textStyle: {
        fontFamily: 'Manrope-Bold',
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'left',
        fontSize: 25,
    },
    scrollView: {
        flex: 1,
        marginBottom: 120,
    },

    greenText: {
        color: "green",
    },

    errorText: {
        color: 'red',
        margin: 10,
    },
    successText: {
        color: 'green',
        margin: 10,
    },

    centeredLogo: {
        position: 'absolute',
        top: '10%',
        alignSelf: 'center',
    },

   navigationcontainer: {
        flexDirection: 'row',
    },
    active: {
        borderColor: "#BCC1CA",
    },
    buttonClose: {
        backgroundColor: 'red',
        marginTop: 10,
        flexWrap: 'wrap',
    },
    botBar: {
        backgroundColor: "black",
        position: "fixed",
        bottom: 0,
        flexDirection: "row",
        width: "100%",
        borderColor: "#181a1f",
        borderWidth: 1,
        alignItems: "center",
        paddingVertical: 10,
        justifyContent:'space-between',
        paddingLeft: "10%",
        paddingRight: "10%",
    },

    mobileNavigationText: {
        color: "#555e6c",
        fontFamily: 'Manrope-Bold',
    },
    mobSideBarButton: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: 'center',

    },

});

export default BaseStyle;