import {StyleSheet} from "react-native";

const StudyScreenStyle = StyleSheet.create({
    downFloatingButton: {
        marginTop: "5%",
        width: "100%",
    },

   cardButtonRow: {
        position: "absolute",
        bottom: 0,
        justifyContent: "center",
        align: "center",
        flexDirection: "row",
        flexWrap: 'wrap',
    },
    studyCard: {
        paddingTop: 10,
        marginTop: 15,
        marginHorizontal: 5,
    },
    bullet_list: {
        fontFamily: 'Manrope-Bold',
        color: 'white',
        fontSize: 20,
    },
    text: {
        marginTop: 20,
        fontFamily: 'Manrope-Bold',
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'left',
        fontSize: 20,
    },

    buttonGrade: {
        flex: 1,
        width: 10,
        margin: 4,
        padding: 1,
        backgroundColor: '#1cd75b',
        fontSize: 5,
        borderRadius: "150px",
        paddingVertical: "1%",
        paddingHorizontal: "1%",

    },

    questionText: {
        justifyContent: "center",
        alignItems: 'center',
    },
});

export default StudyScreenStyle;