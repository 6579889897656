import {StyleSheet} from "react-native";

const CardStyle = StyleSheet.create({

    outlineCard: {
        backgroundColor: "white",
        borderWidth: 1,
        borderColor: '#ccc',
        shadowColor: "#000000",
        shadowOpacity: 0.3,
        shadowRadius: 5,
        borderRadius: 6,
        color: '#93abb5',
        marginBottom: 10,
        paddingTop: 20,
        paddingHorizontal: 20,
        paddingBottom: 10,
        width: "100%",
    },
    outlineCardEdit: {
        marginTop: 20,
    },
    cardDropDown: {
        marginRight: 20,
    },
    cardDeckButtonRow: {
        position: "absolute",
        top: "10%",
        flexDirection: "row",
        right:0,
    },
});

export default CardStyle;