import {StyleSheet} from "react-native";

const UploadScreenStyle = StyleSheet.create({

    uploadText: {
        fontFamily: 'Manrope-Bold',
        paddingBottom: "15px",
        fontSize: 20,
        fontWeight: "bold",
        color: 'white',
    },
});

export default UploadScreenStyle;