import React, { useState, useEffect } from 'react';
import { View, TextInput, Pressable, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import axios from 'axios';
import Constants from "expo-constants";
import AsyncStorage from '@react-native-async-storage/async-storage';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import BaseStyle from "../Styles/Base"
import DeckStyle from "../Styles/Deck"

function Deck({ deck, navigateEdit, navigateStudy, reloadCallBack }) {
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [statistics, setStatistics] = useState({todo: 0, correct: 0, unseen: 0});
    const styles = StyleSheet.flatten([BaseStyle, DeckStyle]);

    useEffect(() => {
        fetchStatistics(deck.deck_id);
    }, [deck]);

    const getLogo = () => {
        const pick = deck.deck_id % 7;
        const studyImg = require('../assets/study' + pick + '.png');
        return studyImg;
    };

    const handleDelete = async () => {
        const token = await AsyncStorage.getItem('token');
         fetch(Constants.expoConfig.extra.backend + `/deck/${deck.deck_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
        .then(res => {
            setSuccess("Deleted");
            setError(null);
            console.log("deleted");
            reloadCallBack();
        })
        .catch(err => {
            setError(error);
        });
    };

    const fetchStatistics = async (deckId) => {
        const token = await AsyncStorage.getItem('token');
        fetch(Constants.expoConfig.extra.backend + `/statistics/${deckId}`, {
            method: 'GET',
            headers: {
                'Authorization': token
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                setStatistics({
                    todo: parseInt(data.todos) || 0,
                    correct: parseInt(data.done) || 0,
                    unseen: parseInt(data.unseen) || 0
                });
            })

            .catch(error => {
                console.error("Error fetching statistics: ", error);
            });
    };

    return (
         <View style={styles.listItem}>
           {success && <Text style={styles.successText}>{success}</Text>}
           {error && <Text style={styles.errorText}>{error}</Text>}
            <View style={[styles.deckrow]}>
                    <Image style={ styles.deckLogo} source={getLogo()} />
                    <TouchableOpacity onPress={() => navigateStudy(deck.deck_id, deck.deck_name)}>
                            <Text style={[styles.textStyle, styles.deckText]} >{deck.deck_name}</Text>
                    </TouchableOpacity>
                    <View style={styles.cardDeckButtonRow}>
                        <Pressable style={[styles.iconButton]}
                                onPressIn={() => navigateEdit(deck.deck_id)}>
                                 <Text style={{flex:1}}><FontAwesome name='edit' size="20" /> </Text>
                        </Pressable>
                        <Pressable style={[styles.iconButton]} onPressIn={() => handleDelete()}>
                                 <Text style={{flex:1}}><FontAwesome name='trash' size="20" /> </Text>
                        </Pressable>
                    </View>

            </View>
              <View style={styles.deckCardStatistics}>
                 <Text style={[styles.statisticsText]}> {statistics.correct}/{statistics.todo+statistics.correct+statistics.unseen}</Text>
                 <Text style={[styles.statisticsText, styles.greenText]}> {statistics.unseen} New Cards</Text>
            </View>
        </View>
    );
}

export default Deck;
