import React from "react";
import {Text, Pressable, View, Image} from "react-native";
import BaseStyle from "../Styles/Base"
import PleaseWaitStyle from "../Styles/PleaseWait"
import {StyleSheet} from "react-native";
import FontAwesome from 'react-native-vector-icons/FontAwesome';

const loadingImage = require('../assets/loading.png');

const styles = StyleSheet.flatten([BaseStyle, PleaseWaitStyle]);

const Breadcrumb = ({ navigation, headerText }) => {
    return (
        <View style={styles.pleasewaitcontainer}>
          <Text style={[styles.textStyle, styles.pleasewaittext]}>This may take a while. You can close the app and check back later.</Text>
          <Image style={ styles.pleasewaitimage} source={loadingImage} />
          <Text style={[styles.textStyle, styles.pleasewaittext]}>Generating...</Text>
        </View>
    )
};



export default Breadcrumb;

