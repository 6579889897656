import React, { useState } from 'react';
import { View, TextInput, Pressable, Text, StyleSheet } from 'react-native';
import axios from 'axios';
import Constants from "expo-constants";
import AsyncStorage from '@react-native-async-storage/async-storage';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import BaseStyle from "../Styles/Base"
import CardStyle from "../Styles/Card"

function Card({ card, deckId, reloadCallBack }) {
    const [isShown, setShown] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [question, setQuestion] = useState(card.question);
    const [answer, setAnswer] = useState(card.answer);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const styles = StyleSheet.flatten([BaseStyle, CardStyle]);


    const handleSave = async () => {
        const token = await AsyncStorage.getItem('token');
        fetch(Constants.expoConfig.extra.backend + `/card/${card.card_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                question: question,
                answer: answer,
            }),
        })
        .then(res => {
            setSuccess("Saved");
            setEditing(false);
            setError(null);
            (response) => response.json()
            reloadCallBack();
        })
        .catch(err => {
            setError(err);
        });
    };

    const handleDelete = async () => {
        const token = await AsyncStorage.getItem('token');
         fetch(Constants.expoConfig.extra.backend + `/card/${card.card_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
        .then(res => {
            setSuccess("Deleted");
            setEditing(false);
            (response) => response.json()
            reloadCallBack();
        })
        .catch(err => {
            setError(error);
        });

    };

    return (
        <View>
            <View style={styles.listItem}>
                <Text style={styles.textStyle}>{card.question}</Text>
                <View style={[styles.cardDeckButtonRow, styles.cardDropDown]}>
                    {isShown ? (
                        <Pressable onPress={()=> setShown(false)}>
                                <Text style={styles.textStyle}><FontAwesome name='caret-up' size={20} /></Text>
                        </Pressable>
                    ) : (
                        <Pressable onPress={()=> setShown(true)}>
                                 <Text style={styles.textStyle}><FontAwesome name='caret-down' size={20} /></Text>
                        </Pressable>
                    )}
                </View>
                {success && <Text style={styles.successText}>{success}</Text>}
                {error && <Text style={styles.errorText}>{error}</Text>}
            </View>
                {isShown ?

                    isEditing ? (
                        <View style={[styles.listItem, styles.outlineCardEdit, styles.active]}>
                            <TextInput style={styles.input} value={question} onChangeText={setQuestion} />
                            <TextInput style={styles.input} value={answer} onChangeText={setAnswer} />
                            <View style={styles.cardButtonRow}>
                                <Pressable style={[styles.iconButton, styles.buttonClose]} onPress={handleDelete}>
                                          <Text style={styles.buttonText}><FontAwesome name='remove' size={20} /> Delete</Text>
                                </Pressable>
                                <Pressable style={styles.iconButton} onPress={handleSave}>
                                    <Text style={styles.buttonText}><FontAwesome name='save' size={20} /> Save</Text>
                                </Pressable>
                            </View>

                        </View>
                    ) : (
                        <View style={[styles.listItem, styles.active]}>
                            <Text style={styles.textStyle}>{card.answer}</Text>
                            <View style={styles.cardButtonRow}>
                               <Pressable style={[styles.iconButton, styles.buttonClose]} onPress={handleDelete}>
                                          <Text style={styles.buttonText}><FontAwesome name='remove' size={20} /> Delete</Text>
                                </Pressable>
                                 <Pressable style={styles.iconButton} onPress={() => setEditing(true)}>
                                    <Text style={styles.buttonText}><FontAwesome name='edit' size={20} /> Edit</Text>
                                </Pressable>
                            </View>
                        </View>
                    ) : (<View></View>)
                    }
        </View>
    );
}

export default Card;
