import React, {useState, useEffect} from 'react';
import {View, Text} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from "expo-constants";
import BaseStyle from "../Styles/Base"
import StatisticBarStyle from "../Styles/StatisticBar"
import {StyleSheet} from "react-native";

const StatisticBar = ({deckId, refresh, isMobile}) => {
    const [statistics, setStatistics] = useState({todo: 0, correct: 0, unseen: 0});
    const styles = StyleSheet.flatten([BaseStyle, StatisticBarStyle]);

    const fetchStatistics = async () => {
        const token = await AsyncStorage.getItem('token');
        fetch(Constants.expoConfig.extra.backend + `/statistics/${deckId}`, {
            method: 'GET',
            headers: {
                'Authorization': token
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                setStatistics({
                    todo: data.todos || 0,
                    correct: data.done || 0,
                    unseen: data.unseen || 0
                });
            })

            .catch(error => {
                console.error("Error fetching statistics: ", error);
            });
    };

    useEffect(() => {
        fetchStatistics();
    }, [deckId, refresh]);

    return (
        <View style={[isMobile ? styles.statisticsContainerMobile : styles.statisticsContainer, styles.row]}>
            <View style={{flex: 1, flexDirection: 'column'}}>
                <Text style={{color: 'red', fontSize: '100%'}}>{statistics.todo}</Text>
                <Text style={styles.textStyleStatisticBox}>ToDo</Text>
            </View>
            <View style={{flex: 1, flexDirection: 'column'}}>
                <Text style={{color: 'green',fontSize: '100%'}}>{statistics.correct}</Text>
                <Text style={styles.textStyleStatisticBox}>Correct</Text>
            </View>
            <View style={{flex: 1, flexDirection: 'column'}}>
                <Text style={{color: 'blue',fontSize: '100%'}}>{statistics.unseen}</Text>
                <Text style={styles.textStyleStatisticBox}>Unseen</Text>
            </View>
        </View>
    );
};

export default StatisticBar;
