import {StyleSheet} from "react-native";

const PleaseWaitStyle = StyleSheet.create({
  pleasewaitcontainer: {
    align: "center",
    justifyContent: 'center',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',

  },
  pleasewaittext: {
    textAlign: 'center',
    color: "#a7adb7",
    },

    pleasewaitimage: {
        width: 300,
        height: 120,
        resizeMode: 'contain',
        marginTop: 30,
        marginTop: 15,

    },

});

export default PleaseWaitStyle;