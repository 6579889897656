import React from "react";
import {Text, Pressable, View} from "react-native";
import BaseStyle from "../Styles/Base"
import BreadcrumbStyle from "../Styles/Breadcrumb"
import {StyleSheet} from "react-native";
import FontAwesome from 'react-native-vector-icons/FontAwesome';

const styles = StyleSheet.flatten([BaseStyle, BreadcrumbStyle]);

const Breadcrumb = ({ navigation, headerText }) => {
    return (
        <Pressable onPress={() => navigation.goBack()}>
            <Text style={[styles.navigationText, styles.breadcrumbText]}>
                <FontAwesome name='arrow-left' size={20} />
                <View style={{ marginLeft: 10}}>
                    <Text style={{ marginTop: 20 }}>{headerText}</Text>
                </View>
            </Text>
        </Pressable>
    )
};



export default Breadcrumb;

