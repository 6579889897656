import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Pressable, FlatList, TouchableOpacity, ScrollView, TextInput} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from "expo-constants";
import Deck from "./Deck";
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import BaseStyle from "../Styles/Base"
import HomeStyle from "../Styles/Home"

const HomeScreen = ({ route, navigation }) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isRefresh, setRefresh] = useState(null);
    const [search, setSearch] = useState([]);
    const styles = StyleSheet.flatten([BaseStyle, HomeStyle]);
    const { isMobile } = route.params;

    useEffect(() => {
        fetchData();
    }, [isRefresh]);

    const fetchData = async () => {
        const token = await AsyncStorage.getItem('token');
        fetch(Constants.expoConfig.extra.backend + '/deck', {
            method: 'GET',
                headers: {
                    'Authorization': token
                },
            })
            .then((response) => {
                response.json().then((returnString) => {
                    console.log(returnString);
                    setData(returnString);
                    setError(null);
                })
                .catch((error) => {
                    setError('Error reading data'); // Set the error message
                });
            })
            .catch((error) => {
                setError('Error fetching data'); // Set the error message
            });

    };

    return (
        <View style={isMobile ? styles.container : styles.containerDesktop}>

            <View style={styles.homeScreenSearchBox}>
               {success && <Text style={styles.successText}>{success}</Text>}
               {error && <Text style={styles.errorText}>{error}</Text>}
               <TextInput style={styles.input}
                    left={<FontAwesome name='search' size={5} />}
                    placeholder="Search"
                    value={search}
                    onChangeText={setSearch}
                />
               <Pressable style={[styles.button]} onPress={() => navigation.navigate('Upload')}>
                    <Text style={styles.buttonText}><FontAwesome name='plus' size={12} /> New Deck</Text>
               </Pressable>
                <Text style={[styles.navigationText, styles.homeText]}> Decks </Text>
            </View>
           <ScrollView style={styles.scrollView}  contentContainerStyle={{ flexGrow: 1 }} vertical>
                    {data.filter((item, index) => search != null && item.deck_name.includes(search)).map((item, index) => {
                        return <Deck key={index+Date.now()} deck={item}
                         navigateEdit={(id) => navigation.navigate('CardEditScreen', { response: id })}
                         navigateStudy={(id, name) => navigation.navigate('StudyScreen', { response: id, deckName: name })}
                         reloadCallBack={() => setRefresh([])} />
                    })}
            </ScrollView>
        </View>
    );
};

export default HomeScreen;
