import {StyleSheet} from "react-native";

const DeckStyle = StyleSheet.create({
    listItem: {
        flexWrap: 'wrap',
        paddingRight: 25,
        paddingLeft: 25,
        paddingBottom: 5,
        paddingTop: 15,
        marginBottom: 20,
        fontSize: 30,
        color: "white",
        borderWidth: 1,
        width: "100%",
        flex:1,

    },

    deckLogo: {
        width: 60,
        height: 60,
        resizeMode: 'stretch',
        position: "absolute",
        borderRadius: 20,
        top: "70%",
        left: 0,
    },
    deckText: {
        marginLeft: 95,
        marginTop: 40,
        fontSize: "70%",
    },
    statisticsText: {
        color: "#9095a0",
        fontFamily: 'Manrope-Bold',
        marginRight: 30,
        marginTop: 40,
        fontSize: "50%",
    },
     deckCardStatistics: {
        flexDirection: "row",
        marginLeft: 95,
    },

    deckrow: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    iconButton: {
        backgroundColor: "#eab923",
        marginBottom: 18,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "12px",
        padding: 10,
        marginRight: 10,
        width: "40%",
        alignSelf: 'center',
    },
    cardDeckButtonRow: {
        position: "absolute",
        flexDirection: "row",
        right:-20,
        top: 40,
    },
});

export default DeckStyle;