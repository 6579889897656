import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
const logoImage = require('../assets/Logoneu_Farbig.png');
const profileImg = require('../assets/profile.png');
import BaseStyle from "../Styles/Base"
import SidebarStyle from "../Styles/Sidebar"

const Sidebar = ({navigation, logout }) => {
  const navigateToScreen = (screenName) => {
    navigation.navigate(screenName);
  };
const styles = StyleSheet.flatten([BaseStyle, SidebarStyle]);



  return (
    <View style={styles.sidebarContainer}>
        <View style={styles.welcome}>
            <Image style={ styles.logo} source={logoImage} />
            <Text style={styles.sidebarWelcomeText}> Welcome </Text>
            <Image style={styles.profileImg} source={profileImg} />
        </View>
        <View style={styles.itemContainer}>
          <TouchableOpacity
            style={styles.sidebarItem}
            onPress={() => navigateToScreen('Home')}
          >
            <Text style={styles.navigationText}><FontAwesome name='home' size={20} /> Home</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.sidebarItem}
            onPress={() => navigateToScreen('Upload')}
            >
                  <Text style={styles.navigationText}><FontAwesome name='upload' size={20} /> Upload</Text>
            </TouchableOpacity>

          <TouchableOpacity
            style={styles.sidebarItem}
            onPress={() => logout()}
            >
                  <Text style={styles.navigationText}><FontAwesome name='sign-out' size={20} /> Logout</Text>
        </TouchableOpacity>
        </View>
    </View>
  );
};



export default Sidebar;