import {StyleSheet} from "react-native";

const MobileBarStyle = StyleSheet.create({
    topBar: {
          backgroundColor: 'black', // Adjust the background color as needed
          alignItems: 'center',

    },
    logo: {
        width: 120,
        height: 50,
        resizeMode: 'contain',
    },

    navigationText: {
        fontFamily: 'Manrope-Bold',
        color: "white",
        fontSize: 25,
        marginBottom: 10,
    },
    profileImg: {
        position: "absolute",
        right: 0,
        top: "30%",
        marginRight: 50,
        width: 40,
        height: 40,
        borderRadius: 75,
        paddingBottom: 10,
    },

});

export default MobileBarStyle;