import * as DocumentPicker from 'expo-document-picker';


import React, {useState, useEffect} from 'react';
import {Alert, Button, StyleSheet, TextInput, Modal, Pressable, View, Text, ScrollView} from "react-native";
import Constants from "expo-constants";
import {useNavigation} from '@react-navigation/native';
import Card from "./Card";
import {create} from "axios";
import card from "react-native-paper/src/components/Card/Card";
import AsyncStorage from '@react-native-async-storage/async-storage';
import styles from "../Styles/Base";
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import Breadcrumb from "./Breadcrumb";
import BaseStyle from "../Styles/Base"
import CardEditScreenStyle from "../Styles/CardEditScreen"

const CardEditScreen = ({route, navigation}) => {
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [question, setQuestion] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [isRefresh, setRefresh] = useState([]);
    const styles = StyleSheet.flatten([BaseStyle, CardEditScreenStyle]);
    const { isMobile } = route.params;

    const deckId = route.params?.response

    useEffect(() => {
        fetchCards();
    }, [isRefresh]);


    const componentDidMount = () => {
     console.log("mounted");
    }
    const fetchCards = async () => {
        const token = await AsyncStorage.getItem('token');
        fetch(Constants.expoConfig.extra.backend + '/deck/' + deckId +'/card', {
                method: 'GET',
                headers: {
                    'Authorization': token
            },
            })
            .then((response) => {
                response.json().then((returnString) => {
                   console.log("CardOverViewScreen", returnString);
                   setData(returnString);
                   setError(null);
                })

            })
            .catch((error) => {
                setError('Error fetching data'); // Set the error message
            });
    }


    const newCard = async () => {
        const token = await AsyncStorage.getItem('token');
        fetch(Constants.expoConfig.extra.backend + '/deck/' + deckId +'/card', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
            },
            body: JSON.stringify({
                question: question,
                answer: answer,
            }),
            })
            .then((response) => {
                response.text().then((returnString) => {
                   setError(null);
                   setIsAlertVisible(false);
                   setRefresh([]);
                })
            })
            .catch((error) => {
                setError('Error creating card'); // Set the error message
            });
    }





    return (
        <View style={isMobile ? styles.container : styles.containerDesktop}>

            {error && <Text style={styles.errorText}>{error}</Text>}

            <Breadcrumb navigation={navigation} headerText="Edit Deck" />

           <ScrollView style={styles.scollView}>
                {!isAlertVisible &&
                     <Pressable style={[styles.button, styles.buttonIcon]} onPress={() => setIsAlertVisible(true)}>
                        <Text style={styles.buttonText}><FontAwesome name='plus' size={20} /> New Card</Text>
                    </Pressable>
                 }
                   {isAlertVisible && (
                        <View style={[styles.listItem, styles.active]}>
                            <TextInput
                                style={styles.input}
                                onChangeText={text => setQuestion(text)}
                                placeholder="Question..."
                            />
                            <TextInput
                                style={styles.input}
                                onChangeText={text => setAnswer(text)}
                                placeholder="Answer..."
                            />
                            <Pressable
                                style={[styles.button]}
                                onPress={() => newCard()}>
                                <Text style={styles.textStyle}>Save</Text>
                            </Pressable>
                            <Pressable
                                style={[styles.button, styles.buttonClose]}
                                onPress={() => setIsAlertVisible(false)}>
                                <Text style={styles.textStyle}>Cancel</Text>
                            </Pressable>
                        </View>
                )}
                {data.map((item, index) => {
                    return <Card key={index+Date.now()} card={item} deckId={deckId} reloadCallBack={() => setRefresh([])}/>
                })}
            </ScrollView>



        </View>
    );
};


export default CardEditScreen;
