import {StyleSheet} from "react-native";

const LoginStyle = StyleSheet.create({
    loginRegisterInput: {
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 10,
        marginBottom: 10, // Increased margin between boxes
        width: '100%', // Shortened width
        backgroundColor: 'white',
        color: 'black',
        placeholderTextColor: "#bdc1c9",
    },

    loginScreenBackground: {
        flex: 1,
        backgroundColor: '#000',
        paddingHorizontal: 20,
        paddingTop: 20,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },

    loginFields: {
        marginTop: 5,
        width: '50%',  // Set width to 50%
        marginLeft: '25%',  // Set left margin to 25%
        marginRight: '15%', // Set right margin to 25%
    },
    loginLogo: {
        position: 'absolute',
        top: 120,
        width: "80%",
        height: "60%",
        resizeMode: 'contain',
    },
    headerContainerLoginRegister: {
        paddingTop: 20,
        flex:1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerWelcomeLoginRegister: {
        position: 'absolute',
        top: 20,
        left: 20,
        color: '#9095a0',
        fontSize: 48,
        fontFamily: 'Manrope-Bold',

    },

    headerMessageLoginRegister: {
        position: 'absolute',
        top: 50,
        left: 20,
        color: 'white',
        fontSize: 40,
        marginTop: 25,
        fontFamily: 'Manrope-Bold',
    },
    loginFieldText: {
        color: '#a7adb8',
        marginBottom: 3,
        fontFamily: 'Manrope-Bold',
        fontSize: 15,
        width: '100%',
    },
     buttonLoginRegister: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 9,
        paddingHorizontal: 32,
        elevation: 3,
        backgroundColor: '#eab923',
        width: "100%",
    },
    buttonTextLogin: {
        fontSize: 16,
        lineHeight: 21,
        fontFamily: 'Manrope-Regular',
        letterSpacing: 0.25,
        color: 'black',
        textAlign: "center",
    },

    forgotPassword: {
        textDecorationLine: 'underline',
        color: 'orange',
    },
    signUp: {
        color: 'orange',
    },
    forgotPasswordPressable: {
        alignSelf: 'flex-end',  // This will push the element to the right within its parent
        marginRight: 0,  // Ensure there's no margin on the right
        marginBottom: 10,
    },

    inputWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        width: '100%'
    },
    inputIcon: {
        position: 'absolute',
        left: 10, // adjust this to move the icon left or right within the TextInput
        top: '45%', // This centers it vertically
        transform: [{translateY: -10}], // Half the height of your icon to ensure perfect centering
        width: 15,
        height: 15,
    },
    passwordToggleIcon: {
        position: 'absolute',
        right: 10,
        top: '45%',
        width: 15, // Increased to provide more space
        height: 15,
        justifyContent: 'center', // Ensure content is centered
        alignItems: 'center',
        transform: [{translateY: -10}], // Adjust for half of 25
    },
    headerWelcome: {
        position: 'absolute',
        top: 20,
        left: 20,
        color: 'white',
        fontSize: 24,
    },

    headerMessage: {
        position: 'absolute',
        top: 50,
        left: 20,
        color: 'white',
        fontSize: 18,
    },
});

export default LoginStyle;