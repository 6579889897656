import React, { useState } from 'react';
import { Text, TextInput, View, Pressable } from 'react-native';
import Constants from "expo-constants";
import BaseStyle from "../Styles/Base"
import LoginStyle from "../Styles/Login"
import {StyleSheet} from "react-native";

const ConfirmResetPasswordScreen = ({ route, navigation }) => {
    const [confirmationCode, setConfirmationCode] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [error, setError] = useState(null);
    const { username } = route.params;
    const styles = StyleSheet.flatten([BaseStyle, LoginStyle]);

    const confirmResetPassword = () => {
        if (newPassword1 !== newPassword2) {
            setError('Passwords do not match');
            return;
        }

        fetch(Constants.expoConfig.extra.backend + '/confirm_reset_password', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: username,
                confirmation_code: confirmationCode,
                new_password1: newPassword1,
                new_password2: newPassword2
            }),
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.error) {
                setError(data.error);
            } else {
                // Handle success, e.g., navigate to login screen or show a success message
                navigation.navigate('Login');
            }
        })
        .catch((error) => setError(error));
    };

    return (
        <View style={styles.container}>
            {error && <Text style={styles.errorText}>{error}</Text>}

            <TextInput
                style={styles.input}
                placeholder="6-digit confirmation code"
                value={confirmationCode}
                onChangeText={setConfirmationCode}
                keyboardType="numeric"
                maxLength={6}
            />
            <TextInput
                style={styles.input}
                placeholder="New Password"
                value={newPassword1}
                onChangeText={setNewPassword1}
                secureTextEntry
            />
            <TextInput
                style={styles.input}
                placeholder="Confirm New Password"
                value={newPassword2}
                onChangeText={setNewPassword2}
                secureTextEntry
            />
            <Pressable style={styles.button} onPress={confirmResetPassword}>
                <Text style={styles.buttonText}>Confirm Reset</Text>
            </Pressable>
        </View>
    );
};

export default ConfirmResetPasswordScreen;
