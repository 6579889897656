import {StyleSheet} from "react-native";

const SidebarStyle = StyleSheet.create({
    sidebarContainer: {
        backgroundColor: '#1d2128', // Adjust the background color as needed
        paddingTop: 20, // Adjust the top padding as needed
        minWidth: '15%',
    },
    sidebarItem: {
        paddingVertical: 10,
        borderColor: '#ddd',

    },
    sidebarWelcomeText: {
        fontFamily: 'Manrope-Bold',
        paddingBottom: "15px",
        fontSize: 15,
        fontWeight: "bold",
        color: 'white',
    },
    welcome: {
        alignItems: 'center',
        marginBottom: 10,
        width: "100%",
    },
    logo: {
        width: "100%",
        height: 50,
        marginLeft: 10,
        resizeMode: 'contain',
    },
    navigationText: {
        fontFamily: 'Manrope-Bold',
        color: '#555e6c',
        fontSize: 15,
    },
    itemContainer: {
        paddingHorizontal: 10,
    },
    profileImg: {
        width: 30,
        height: 30,
        borderRadius: 75,
        paddingBottom: 10,
    },
});

export default SidebarStyle;