import {StyleSheet} from "react-native";

const HomeStyle = StyleSheet.create({
    homeScreenSearchBox: {
        width: "100%",
        marginBottom: 10,
        marginTop: 20,
    },
    homeText: {
        marginTop: 20,
        left: 0,
        fontSize: 15,
    },
    navigationText: {
        fontFamily: 'Manrope-Bold',
        color: '#555e6c',
        fontSize: 15,
    },
});

export default HomeStyle;