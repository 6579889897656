import React, {useState} from 'react';
import {Image, Pressable, Text, TextInput, View} from "react-native";
import Constants from "expo-constants";
import BaseStyle from "../Styles/Base"
import LoginStyle from "../Styles/Login"
import {StyleSheet} from "react-native";
import staticImage from "../assets/flashstudy_cut.png";
import userIcon from "../assets/icons/user.png";


const RegisterScreen = ({navigation}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const staticImage = require("../assets/Logoneu_weiß.png");
    const userIcon = require("../assets/icons/user.png");
    const passwordIcon = require("../assets/icons/passwort.png")
    const mailIcon = require("../assets/icons/email.png")
    const styles = StyleSheet.flatten([BaseStyle, LoginStyle]);
    const handleRegister = () => {
        fetch(Constants.expoConfig.extra.backend + '/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
                password: password,
                password2: confirmPassword,
                email: email,
            }),
        })
            .then(() => {
                setError(null);
                navigation.navigate('Login')
            })
            .catch((error) => {
                setError(error);
            });

    }

    return (
        <View style={styles.loginScreenBackground}>

            <View style={styles.headerContainerLoginRegister}>
                <Text style={styles.headerWelcomeLoginRegister}>Welcome.</Text>
                <Text style={styles.headerMessageLoginRegister}>Create an account</Text>
                <Image style={styles.loginLogo} source={staticImage}/>
            </View>

            <View style={styles.loginFields}>
                {error && <Text style={styles.errorText}>{error}</Text>}
                <Text style={styles.loginFieldText}>Username</Text>
                <View style={styles.inputWrapper}>
                    <Image source={userIcon} style={styles.inputIcon}/>
                    <TextInput
                        style={[styles.loginRegisterInput, {paddingLeft: 30}]} // Adjust paddingLeft to give space for the icon
                        placeholder="Enter Username"
                        value={username}
                        onChangeText={setUsername}
                    />
                </View>
                <Text style={styles.loginFieldText}>Email</Text>
                <View style={styles.inputWrapper}>
                    <Image source={mailIcon} style={styles.inputIcon}/>
                    <TextInput
                        style={[styles.loginRegisterInput, {paddingLeft: 30}]} // Adjust paddingLeft to give space for the icon
                        placeholder="Enter Email"
                        value={email}
                        onChangeText={setEmail}
                    />
                </View>
                <Text style={styles.loginFieldText}>Password</Text>
                <View style={styles.inputWrapper}>
                    <Image source={passwordIcon} style={styles.inputIcon}/>
                    <TextInput
                        style={[styles.loginRegisterInput, {paddingLeft: 30}]} // Adjust paddingLeft to give space for the icon
                        placeholder="Enter Password"
                        value={password}
                        onChangeText={setPassword}
                        secureTextEntry
                    />
                </View>
                <Text style={styles.loginFieldText}>Confirm Password</Text>
                <View style={styles.inputWrapper}>
                    <Image source={passwordIcon} style={styles.inputIcon}/>
                    <TextInput
                        style={[styles.loginRegisterInput, {paddingLeft: 30}]} // Adjust paddingLeft to give space for the icon
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChangeText={setConfirmPassword}
                        secureTextEntry
                    />
                </View>

                <Pressable style={[styles.buttonLoginRegister, {marginTop: '3%'}]} onPress={handleRegister}>
                    <Text style={styles.buttonTextLogin}>Register</Text>
                </Pressable>

            </View>
            <View style={{flex: 1, alignItems: 'center', marginTop: '8%'}}>

                <Text style={{color: '#6e7787'}}>Already have an account?</Text>
                <Text style={styles.signUp} onPress={() => navigation.navigate('Login')}>
                    Sign In
                </Text>
            </View>

        </View>
    );
};
export default RegisterScreen;
