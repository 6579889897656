import AsyncStorage from '@react-native-async-storage/async-storage';

import React, {useState} from 'react';
import {Image, Pressable, Text, TextInput, View} from "react-native";
import Constants from "expo-constants";
import BaseStyle from "../Styles/Base"
import LoginStyle from "../Styles/Login"
import {StyleSheet} from "react-native";

const LoginScreen = ({navigation, setIsLoggedIn, setUsername}) => {
    const [usernameInput, setUsernameInput] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const staticImage = require("../assets/Logoneu_weiß.png");
    const userIcon = require("../assets/icons/user.png");
    const passwordIcon = require("../assets/icons/passwort.png")
    const [isPasswordVisible, setIsPasswordVisible] = useState(false); // false means the password is hidden by default
    const hideIcon = require("../assets/icons/show.png");
    const showIcon = require("../assets/icons/auge.png");
    const styles = StyleSheet.flatten([BaseStyle, LoginStyle]);

    const handleLogin = () => {
        fetch(Constants.expoConfig.extra.backend + '/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: usernameInput,
                password: password,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.token) {
                    AsyncStorage.setItem('token', data.token);
                    AsyncStorage.setItem('username', usernameInput);
                    setIsLoggedIn(true); // <-- Set isLoggedIn to true
                    setUsername(usernameInput); // <-- Set username here
                } else {
                    setError('Could not log in Please check your username and password');
                }
            })
            .catch((error) => {
                setError(error);
            });
    }
    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <View style={styles.loginScreenBackground}>

            <View style={styles.headerContainerLoginRegister}>

                <Text style={styles.headerWelcomeLoginRegister}>Hello.</Text>
                <Text style={styles.headerMessageLoginRegister}>Welcome back</Text>
                <Image style={styles.loginLogo} source={staticImage}/>

            </View>

            <View style={styles.loginFields}>
                {error && <Text style={styles.errorText}>{error}</Text>}
                <Text style={styles.loginFieldText}>Username</Text>
                <View style={styles.inputWrapper}>
                    <Image source={userIcon} style={styles.inputIcon}/>
                    <TextInput
                        style={[styles.loginRegisterInput, {paddingLeft: 30}]} // Adjust paddingLeft to give space for the icon
                        placeholder="Enter Username"
                        value={usernameInput}
                        onChangeText={setUsernameInput}
                        onSubmitEditing={handleLogin}
                    />
                </View>


                <Text style={styles.loginFieldText}>Password</Text>
                <View style={styles.inputWrapper}>
                    <Image source={passwordIcon} style={styles.inputIcon}/>
                    <TextInput
                        style={[styles.loginRegisterInput, {paddingLeft: 30}]}
                        placeholder="Enter password"
                        value={password}
                        onChangeText={setPassword}
                        onSubmitEditing={handleLogin}
                        secureTextEntry={!isPasswordVisible} // make this dynamic
                    />
                    <Pressable onPress={togglePasswordVisibility} style={styles.passwordToggleIcon}>
                        <Image source={isPasswordVisible ? hideIcon : showIcon}
                               style={{width: '100%', height: '100%'}}/>
                    </Pressable>
                </View>


                <Pressable style={styles.forgotPasswordPressable} onPress={() => navigation.navigate('ForgotPassword')}>

                    <Text style={styles.forgotPassword} onPress={() => navigation.navigate('ForgotPassword')}>
                        Forgot Password?
                    </Text>

                </Pressable>

                <Pressable style={styles.buttonLoginRegister} onPress={handleLogin}>
                    <Text style={styles.buttonTextLogin}>Sign In</Text>
                </Pressable>

            </View>
            <View style={{flex: 1, alignItems: 'center', marginTop: '8%'}}>
                <Text style={{color: '#6e7787'}}>OR CONTINUE WITH</Text>
                <View style={styles.thirdPartyLogin}>

                </View>
                <Text style={{color: '#6e7787'}}>Don't have an Account?</Text>
                <Text style={styles.signUp} onPress={() => navigation.navigate('Register')}>
                    Sign Up
                </Text>
            </View>

        </View>
    );

};

export default LoginScreen;