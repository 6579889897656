import {StyleSheet} from "react-native";

const BreadcrumbStyle = StyleSheet.create({
  breadcrumbText: {
        fontSize: 30,
        marginBottom: 45,
        left: 0,
        width: 250,
        height: 50,
    },
     navigationText: {
        color: '#555e6c',
        fontSize: 15,
    },
});

export default BreadcrumbStyle;