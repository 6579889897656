import * as DocumentPicker from 'expo-document-picker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import React, {useState} from 'react';
import {Button, StyleSheet, Text, View, Alert, Modal, Pressable, TextInput, ScrollView} from "react-native";
import Constants from "expo-constants";
import {useNavigation} from '@react-navigation/native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import Slider from '@react-native-community/slider';
import BaseStyle from "../Styles/Base"
import UploadScreenStyle from "../Styles/UploadScreen"
import Breadcrumb from "./Breadcrumb";
import PleaseWait from "./PleaseWait";

const UploadScreen = ({route, navigation}) => {
    const [file, setFile] = useState(null);
    const [cardsPerDay, setCardsPerDay] = useState(2);
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [deckName, setDeckName] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isProcessed, setProcessed] = useState(false);
    const styles = StyleSheet.flatten([BaseStyle, UploadScreenStyle]);
    const { isMobile } = route.params;


    const pickDocument = async () => {
        var result = await DocumentPicker.getDocumentAsync({type: "application/pdf"});
        setFile(result);
    };


    const uploadDocument = async () => {
        if (!file) {
            console.error('No file selected');
            return;
        }
        setIsAlertVisible(true);
        //setSuccess("The Cards will now be generated. This may take some time, you are free to leave this page :) .");
        setProcessed(true);
        setIsAlertVisible(false);
        generateCards();
    };

    const generateCards = async () => {
        const token = await AsyncStorage.getItem('token');
        const formData = new FormData();
        formData.append("file", file.file, file.name)
        formData.append("deckname", deckName)
        formData.append("cardsPerDay", cardsPerDay)
        fetch(Constants.expoConfig.extra.backend + '/getcards', {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: formData,
        })
            .then((response) => {
                response.json().then((returnString) => {
                    console.log("uploadpdf", returnString)
                    navigation.navigate("CardOverview", {response: returnString.deck_id});
                })
            })
            .catch((error) => {
               setError(error);
            });
    };




    return (
        <View style={isMobile ? styles.container : styles.containerDesktop}>
           <Breadcrumb navigation={navigation} headerText='Add Deck'/>
           <ScrollView style={styles.scollView}>
            <View style={[styles.listItem]}>
                {success && <Text style={styles.successText}>{success}</Text>}
                {error && <Text style={styles.errorText}>{error}</Text>}
                {!isProcessed && <>
                    <Text style={styles.uploadText}>Add Deck</Text>
                     <TextInput
                        style={styles.input}
                        onChangeText={text => setDeckName(text)}
                        placeholder="Deckname..."
                    />

                  <Text style={styles.textStyle}>{cardsPerDay} new Cards per Day</Text>
                  <Slider
                    value={cardsPerDay}
                    onValueChange={setCardsPerDay}
                    maximumValue={60}
                    minimumValue={1}
                    step={1}
                    trackStyle={{ height: 50, backgroundColor: 'transparent' }}
                    thumbStyle={{ height: 50, width: 20, backgroundColor: 'transparent' }}
                  />

                    {!file && <Pressable style={styles.button} onPress={pickDocument}>
                        <Text style={styles.buttonText}><FontAwesome name='download' size={20} /> Select PDF</Text>
                    </Pressable> }
                    {file && <Text style={styles.textStyle}>{file.name}</Text>}

                    {!isProcessed && file &&
                    <Pressable style={styles.button} onPress={uploadDocument}>
                              <Text style={styles.buttonText}><FontAwesome name='upload' size={20} /> Create Deck</Text>
                    </Pressable> }
                    </>}
                    {isProcessed && <PleaseWait/>}


                </View>
                </ScrollView>
        </View>
    );


};

export default UploadScreen;
