import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Pressable, FlatList, TouchableOpacity, ScrollView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from "expo-constants";
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import StatisticBar from "./StatisticBar";
import Breadcrumb from "./Breadcrumb";
import BaseStyle from "../Styles/Base"
import StudyScreenStyle from "../Styles/StudyScreen"
import Markdown from 'react-native-markdown-display';


const StudyScreen = ({ route, navigation }) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isRefresh, setRefresh] = useState([]);
    const [isAnswer, setShowAnswer] = useState(false);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [refreshStatistics, setRefreshStatistics] = useState(false);
    const deckId = route.params?.response
    const deckName = route.params?.deckName
    const styles = StyleSheet.flatten([BaseStyle, StudyScreenStyle]);
    const { isMobile } = route.params;

    useEffect(() => {
        fetchCards();
    }, [isRefresh]);



    const currentCard = () => {
        console.log(data);
        return data[currentCardIndex];
    };
    const nextCard = () => {
        if (currentCardIndex >= data.length - 1) {
            setRefresh([]);
            setCurrentCardIndex(0);
        } else {
            setCurrentCardIndex(currentCardIndex+1);
        }
        setShowAnswer(false);
        setError(null);
        setSuccess(null);
    };
    const fetchCards = async () => {
        const token = await AsyncStorage.getItem('token');
        fetch(Constants.expoConfig.extra.backend + '/review/' + deckId, {
                method: 'GET',
                headers: {
                    'Authorization': token
            },
            })
            .then((response) => {
                response.json().then((returnString) => {
                   console.log("StudyPage", returnString);
                   setData(returnString);
                   setError(null);
                })

            })
            .catch((error) => {
                setError('Error fetching data'); // Set the error message
            });
    }
    const handleGrade = async (grade) => {
        const token = await AsyncStorage.getItem('token');
        fetch(Constants.expoConfig.extra.backend + `/review/${currentCard()["card_id"]}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                user_grade: grade,
            }),
        })
        .then(res => {
            nextCard();
            setRefreshStatistics(!refreshStatistics);
        })
        .catch(err => {
            setError(err);
        });
    };
    const showQuestion = () => (
            <View style={styles.listItem}>
              {data.length > 0 &&
               <>
                   <Text style={styles.textStyle}>{currentCardIndex}/{data.length}</Text>
                   <Text style={[styles.questionText, styles.textStyle]}>{currentCard().question}</Text>
                   <View style={styles.container}>
                      {!isAnswer && <Pressable style={[styles.button, styles.downFloatingButton]} onPress={() => setShowAnswer(true)}>
                            <Text style={styles.buttonText}>Show Answer</Text>
                       </Pressable> }
                   </View>
              </>
              }
              {data.length <= 0 && <Text style={styles.textStyle}>Nothing to Learn :)</Text>}
          </View>
    );

    const showAnswer = () => (
            <View style={styles.listItem}>
               {data.length > 0 &&
                   <Markdown style={styles}>{currentCard().answer.replaceAll(".", ".\n")}</Markdown>
               }
                        <View style={[styles.row, styles.downFloatingButton]}>
                           <Pressable style={[styles.button, styles.buttonGrade]} onPress={() => handleGrade(0)}>
                                <Text style={styles.buttonText}>Blackout</Text>
                           </Pressable>

                           <Pressable style={[styles.button, styles.buttonGrade]} onPress={() => handleGrade(1)}>
                                <Text style={styles.buttonText}>Wrong</Text>
                           </Pressable>
                          <Pressable style={[styles.button, styles.buttonGrade]} onPress={() => handleGrade(2)}>
                                <Text style={styles.buttonText}>Correct</Text>
                           </Pressable>
                           <Pressable style={[styles.button, styles.buttonGrade]} onPress={() => handleGrade(3)}>
                                <Text style={styles.buttonText}>Good</Text>
                           </Pressable>
                          <Pressable style={[styles.button, styles.buttonGrade]} onPress={() => handleGrade(4)}>
                                <Text style={styles.buttonText}>Easy</Text>
                           </Pressable>
                           <Pressable style={[styles.button, styles.buttonGrade]} onPress={() => handleGrade(5)}>
                                <Text style={styles.buttonText}>Too Easy</Text>
                           </Pressable>
                        </View>
          </View>
    );


    return (
    <View style={isMobile ? styles.container : styles.containerDesktop}>
               {success && <Text style={styles.successText}>{success}</Text>}
               {error && <Text style={styles.errorText}>{error}</Text>}
              <Breadcrumb navigation={navigation} headerText={`Learn ${deckName}.`}/>
              <ScrollView style={styles.scrollView}>
                   {showQuestion()}
                   {isAnswer && showAnswer()}
              </ScrollView>
              <StatisticBar deckId={deckId} refresh={refreshStatistics} isMobile={isMobile}/>
    </View>
    );
};

export default StudyScreen;
