import React, { useState } from 'react';
import { Text, TextInput, View, Pressable } from 'react-native';
import Constants from "expo-constants";
import BaseStyle from "../Styles/Base"

const ForgotPasswordScreen = ({ navigation }) => {
    const [username, setUsername] = useState('');
    const styles = BaseStyle;

    const requestPasswordReset = () => {
        fetch(Constants.expoConfig.extra.backend + '/reset_password', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: username }),
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.error) {
                // Handle the error accordingly
                console.error(data.error);
            } else {
                // Navigate to confirmation screen
                navigation.navigate('ConfirmResetPassword', { username });
            }
        })
        .catch((error) => console.error(error));
    };

    return (
        <View style={styles.container}>
            <TextInput
                style={styles.input}
                placeholder="Username"
                value={username}
                onChangeText={setUsername}
            />
            <Pressable style={styles.button} onPress={requestPasswordReset}>
                <Text style={styles.buttonText}>Request Password Reset</Text>
            </Pressable>
        </View>
    );
};

export default ForgotPasswordScreen;
