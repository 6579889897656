import React, { useState, useEffect } from 'react';
import { Button, Text, View, Image, StyleSheet, TouchableOpacity } from 'react-native';
import UploadScreen from './Components/UploadScreen';
import { NavigationContainer, DefaultTheme, createNavigationContainerRef } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { IconButton } from 'react-native-paper';
import BaseStyle from './Styles/Base';
import MobileBarStyle from './Styles/MobileBar';
import ForgotPasswordScreen from './Components/ForgotPasswordScreen';
import ConfirmResetPasswordScreen from './Components/ConfirmResetPasswordScreen';
import RegisterScreen from './Components/RegisterScreen';
import LoginScreen from './Components/LoginScreen';
import CardEditScreen from './Components/CardEditScreen';
import HomeScreen from './Components/HomeScreen';
import StudyScreen from './Components/StudyScreen';
import Sidebar from './Components/Sidebar';
import AppLoading from 'expo-app-loading';
import FontAwesome from 'react-native-vector-icons/FontAwesome';

import * as Font from 'expo-font';

const styles = StyleSheet.flatten([BaseStyle, MobileBarStyle]);
const Stack = createStackNavigator();
const fetchFonts = () => {
  return Font.loadAsync({
    'Manrope-Regular': require('./assets/fonts/Manrope-Regular.ttf'),
      'Manrope-Bold': require('./assets/fonts/Manrope-Bold.ttf'),
  });
};
const staticImage = require('./assets/Logoneu_Farbig.png');

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'black'
  },
};

export default function App() {
    const [fontLoaded, setFontLoaded] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState(null);
    const navigationRef = createNavigationContainerRef()
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 768;
    const profileImg = require('./assets/profile.png');

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }



  useEffect(() => {
    const checkToken = async () => {
      const token = await AsyncStorage.getItem('token');
      const uname = await AsyncStorage.getItem('username');
      if (token !== null) {
        setIsLoggedIn(true);
        setUsername(uname);
      }
    };

    window.addEventListener('resize', handleWindowSizeChange);

    checkToken();
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const handleLogout = async () => {
    await AsyncStorage.removeItem('token');
    setIsLoggedIn(false);
  };

   const topMobileBar = () => (
    <View style={styles.topBar}>
        <Image style={styles.logo} source={staticImage} />
        <Text style={styles.navigationText}>Hello {username}</Text>
        <Image style={styles.profileImg} source={profileImg} />

    </View>
   );
   const botMobileBar = (navigationRef) => (
    <View style={styles.botBar}>
        <TouchableOpacity style={styles.mobSideBarButton}  onPress={() => navigation.navigate("Home")}>
            <Text style={styles.mobileNavigationText}><FontAwesome name='home' size={20} /></Text>
            <Text style={styles.mobileNavigationText}>Home</Text>
        </TouchableOpacity>
            <TouchableOpacity style={styles.mobSideBarButton} onPress={() => navigation.navigate("Upload")}>
                <Text style={styles.mobileNavigationText}><FontAwesome name='upload' size={20} /></Text>
                <Text style={styles.mobileNavigationText}>Upload</Text>
            </TouchableOpacity>
        <TouchableOpacity style={styles.mobSideBarButton} onPress={() =>handleLogout()}>
           <Text style={styles.mobileNavigationText}><FontAwesome name='sign-out' size={20} /></Text>
           <Text style={styles.mobileNavigationText}>Logout</Text>
        </TouchableOpacity>
    </View>
   );

  const logo = () => (
    <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
      <Image style={styles.logo} source={staticImage} />
    </View>
  );

  const logOutButton = () => (
    <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
      <IconButton icon="logout" onPress={handleLogout} />
    </View>
  );



  return (
  <View style={{flexDirection: "column", width:"100%"}}>
    {isLoggedIn && isMobile && topMobileBar()}

    <SafeAreaProvider style={styles.navigationcontainer}>
        {!fontLoaded ? (<AppLoading
        startAsync={fetchFonts}
        onFinish={() => setFontLoaded(true)}
        onError={console.warn}
      />): (<></>)}

    {isLoggedIn && !isMobile && <Sidebar navigation={navigationRef} logout={handleLogout}/>}
   <NavigationContainer  ref={navigationRef} theme={MyTheme} >
        <Stack.Navigator
        initialRouteName={isLoggedIn ? 'Home' : 'Login'}
        screenOptions={{
            headerShown: false,
        }}
        >
          {isLoggedIn ? (
            <>
              <Stack.Screen
                name="Home"
                component={HomeScreen}
                initialParams={{'isMobile': isMobile}}
                options={{ headerRight: logOutButton }}
              />
              <Stack.Screen
                name="Upload"
                initialParams={{'isMobile': isMobile}}
                component={UploadScreen}
                options={{ headerRight: logOutButton }}
              />
              <Stack.Screen
                name="CardEditScreen"
                initialParams={{'isMobile': isMobile}}
                component={CardEditScreen}
                options={{ headerRight: logOutButton }}
              />
              <Stack.Screen
                name="StudyScreen"
                initialParams={{'isMobile': isMobile}}
                component={StudyScreen}
                options={{ headerRight: logOutButton }}
              />
            </>
          ) : (
            <>
              <Stack.Screen name="Login">
                {(props) => (
                  <LoginScreen {...props} setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} />
                )}
              </Stack.Screen>
              <Stack.Screen name="Register" component={RegisterScreen} />
              <Stack.Screen
                name="ForgotPassword"
                component={ForgotPasswordScreen}
                options={{ title: null }}
              />
              <Stack.Screen
                name="ConfirmResetPassword"
                component={ConfirmResetPasswordScreen}
                options={{ title: null }}
              />
            </>
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaProvider>
        {isLoggedIn && isMobile && botMobileBar(navigationRef)}
    </View>
  );
}

