import {StyleSheet} from "react-native";

const StatisticBarStyle = StyleSheet.create({

     statisticsContainer: {
        position: "fixed",
        bottom: 0,
        left:0,
        justifyContent: 'space-between',
        align: "center",
        padding: 5,
        paddingRight: 25,
        paddingLeft: 25,
        paddingBottom: 30,
        paddingTop: 30,
        backgroundColor: "#1d2128",
        fontSize: 30,
        borderRadius: 3,
        width: "100%",
        textAlign: "center",
    },
     statisticsContainerMobile: {
        position: "fixed",
        bottom: 60,
        justifyContent: 'space-between',
        align: "center",
        padding: 5,
        paddingRight: 25,
        paddingLeft: 25,
        paddingBottom: 30,
        paddingTop: 30,
        backgroundColor: "#1d2128",
        fontSize: 30,
        borderRadius: 3,
        width: "100%",
        textAlign: "center",
    },
    textStyleStatisticBox: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: '50%',
        marginTop: '4%',
    },

});

export default StatisticBarStyle;